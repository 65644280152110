<template>
  <transition
    enter-active-class="LobbyPortholeEnter animated zoomIn"
    leave-active-class="LobbyPortholeLeave"
    appear
  >
    <!-- PORTHOLE PARENT -->
    <div
      class="porthole-parent force-gpu"
      :class="{ 'has-video-showing': showVideo }"
    >
      <!-- PORTHOLE -->
      <div class="porthole">
        <!-- PORTHOLE SHINE -->
        <transition
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
        >
          <div
            v-show="showPortholeShine"
            class="porthole-shine"
          >
            <!-- PORTHOLE SHINE INNER -->
            <div class="porthole-shine-inner"></div>
          </div>
        </transition>

        <!-- PORTHOLE INNER -->
        <div class="porthole-inner">
          <!-- GRADIENT EFFECT (TO ALLOW UNDERNEATH TRANSPARENT BG-COLOR TRANSITION) -->
          <div class="porthole-inner-gradient-effect"></div>
          <!-- LOGO -->
          <div class="porthole-logo variation-2">
            <div class="porthole-logo-moon force-gpu"><div class="bg-fill"></div></div>
            <div class="porthole-logo-bats"></div>
            <!-- <div class="porthole-logo-goo"></div> -->

            <img
              class="porthole-logo-base-glow--hour"
              src="./assets/img/logo-black--hour.svg"
            />
            <img
              class="porthole-logo-base-glow"
              src="./assets/img/logo-black.svg"
            />

            <img
              width="100%"
              class="porthole-logo-base"
              src="./assets/img/logo-5.png"
              alt="Logo"
            />

            <!-- TEXT -->
            <div class="porthole-logo-text">
              <span class="d-block text-1">Chatting,</span>
              <span class="d-block text-2">Nostalgia <span class="text-primary">&amp;</span></span>
              <span class="d-block text-3">Music, Baby.</span>
            </div>
          </div>

          <!-- MESSAGE -->
          <div
            class="porthole-message animated jackInTheBox"
            :key="portholeMessageText"
          >
            <!-- TEXT -->
            <div
              v-html="portholeMessageText"
              class="text d-inline-block"
            ></div>

            <!-- COUNTDOWN -->
            <PortholeCountdown
              v-if="showCountdown"
              :countdown-in-seconds="countdownInSeconds"
              @countdown-complete="countdownComplete = $event"
              @countdown-seconds-remaining="countdownSecondsRemaining = $event"
            />
          </div>

          <!-- PORTHOLE HEAD -->
          <div
            class="porthole-head"
            :class="{ 'is-leaving': streamEnding }"
          ></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { mapGetters } from "vuex";
  import PortholeCountdown from "./LobbyPortholeCountdown.vue";

  export default {
    name: "LobbyPorthole",
    components: {
      PortholeCountdown,
    },
    data() {
      return {
        countdownComplete: null,
        countdownDelayTimeout: null,
        countdownInSeconds: null, // set
        countdownSecondsRemaining: null,
        recentSceneName: null,
      };
    },
    computed: {
      ...mapGetters({
        currentScene: "scenes/getCurrentScene",
        getActiveTask: "tasks/getActiveTask",
      }),
      currentSceneId() {
        return this.currentScene.id;
      },
      portholeMessageText() {
        return (
          (this.currentScene.data.sceneData.portholeConfig &&
            this.currentScene.data.sceneData.portholeConfig[
              this.countdownComplete ? "endText" : "startText"
            ]) ||
          ""
        );
      },
      showCountdown() {
        return !!(
          this.currentScene.data.sceneData.portholeConfig &&
          this.currentScene.data.sceneData.portholeConfig.countdownInSeconds &&
          !this.countdownComplete
        );
      },
      showPortholeShine() {
        return !this.countdownComplete && !this.streamEnding;
      },
      showVideo() {
        return !!this.getActiveTask("lobby", "Show Video");
      },
      streamEnding() {
        return !!(this.currentScene && this.currentScene.data.value === "Stream Ending");
      },
    },
    watch: {
      //------------------------------------------------------------
      // WATCH: CURRENT SCENE ID
      //------------------------------------------------------------

      currentSceneId: {
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal !== oldVal) {
            // Need tests because the scene will disappear.
            const seconds =
              (this.currentScene &&
                this.currentScene.data &&
                this.currentScene.data.sceneData &&
                this.currentScene.data.sceneData.portholeConfig &&
                this.currentScene.data.sceneData.portholeConfig.countdownInSeconds) ||
              0;

            // SAME SCENE: Increase the timer incrementally.
            if (this.currentScene.data.value === this.recentSceneName && !this.countdownComplete) {
              // todo: flash screen and add 24 noise.
              this.countdownInSeconds = this.countdownSecondsRemaining + seconds;
            }

            // NEW SCENE: Set timer to default
            // OR.. BRB screen can reset once timer runs out.
            else {
              this.countdownComplete = false;
              this.countdownInSeconds = seconds;

              // BRB sound effect
              if (this.currentScene.data.value === "Stream BRB")
                this.$store.dispatch("tasks/runTasks", [
                  { uuid: "59307910-ba64-42c6-8554-502aad6d949d" },
                ]);
            }

            // Store recent scene name for comparison check.
            this.recentSceneName = this.currentScene.data.value;
          }
        },
      },

      //------------------------------------------------------------
      // COUNTDOWN COMPLETE
      //------------------------------------------------------------

      countdownComplete() {
        if (this.countdownComplete) {
          // STREAM STARTING TASKS
          if (this.currentScene.data.value === "Stream Starting") {
            this.$store.dispatch("tasks/runTasks", [
              { uuid: "07e3f0db-e430-4bd9-a988-926f47e45a33", canceled: true }, // chat off
              { uuid: "ce63e857-477c-4c18-b1d8-6798d786f992", canceled: false, delayStart: 1000 }, // thriller laugh
              { uuid: "a2ad4486-bf0c-4619-9cf7-f536f962e07d", delayStart: 2000 }, // Scene Chat - transition time is important
              { uuid: "07e3f0db-e430-4bd9-a988-926f47e45a33", delayStart: 10000 }, // chat ON --- canceled: false
            ]);
          }
        }
      },
    },
    // updated() {
    //   console.log("FYI: Lobby porthole re-rendered.");
    // },
  };
</script>

<style lang="scss" scoped>
  .LobbyPortholeEnter {
    animation-duration: 600ms;
  }

  .LobbyPortholeLeave {
    animation: portholeLeave 600ms ease 0s alternate 1 forwards;
    // animation-timing-function: cubic-bezier(1.0, 0.5, 0.8, 1.0);

    @keyframes portholeLeave {
      0% {
        transform: scale(1);
        backdrop-filter: none; // otherwise will be black in OBS
      }
      100% {
        transform: scale(0);
      }
    }
  }

  //-----------------------------------------------------------------
  // PORTHOLE PARENT
  //-----------------------------------------------------------------

  .porthole-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // border: 1px solid white;
    overflow: hidden;
    will-change: transform;
    z-index: 1;
  }

  //-----------------------------------------------------------------
  // PORTHOLE
  //-----------------------------------------------------------------

  .porthole {
    display: flex;
    width: rem-calc(778); // curated
    height: rem-calc(778);
    background: url("./assets/img/intro-porthole@2x.png") no-repeat center center;
    background-size: 100%;
    // border: 1px dashed orange;
  }

  //-----------------------------------------------------------------
  // SHINE
  //-----------------------------------------------------------------

  .porthole-shine {
    // display: none;
    $radius: rem-calc(630);
    position: absolute;
    top: calc(50% - #{rem-calc(20)} - #{$radius/2});
    left: calc(50% - #{$radius/2});
    width: $radius;
    height: $radius;
    // border: 1px dashed orange;
    will-change: transform;
  }

  .porthole-shine-inner {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
    // background: linear-gradient(to right, rgba(lighten($primary, 25%), 0.4) 0%, rgba(white, 0) 70%);
    background: linear-gradient(to right, rgba(lighten($primary, 30%), 0.2) 0%, rgba(white, 0) 70%);
    border-radius: 100%;
    animation: rotate 2s linear infinite;
    will-change: transform;

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .is-performance-mode .porthole-shine-inner {
    animation: none;
  }

  //-----------------------------------------------------------------
  // INNER
  //
  // https://bugs.webkit.org/show_bug.cgi?id=98538
  // -webkit-transform: translateZ(0);
  // -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
  //-----------------------------------------------------------------

  .porthole-inner {
    position: relative; // [LOCKED]
    top: -21px; //rem-calc(-20); // offsetY
    left: -1px;
    width: rem-calc(618); // curated
    height: rem-calc(618);
    // border: 2px solid rgba(white, 0.2);
    margin: auto;
    border-radius: 100%;
    // backdrop-filter: blur(30px);
    // box-shadow: inset 0 10px 20px 5px rgba(black, 0.2);
    box-shadow: inset 0 10px 20px 5px rgba(black, 0.3);
    // background-color: #41424d;
    // background-color: lighten(#27282d, 3%);
    // background: radial-gradient(circle, #40414c 0%, #25262b 100%);
    // background-color: #25262b;

    background-color: #2d2e36;
    overflow: hidden;
    transition: background-color 1.5s ease; // 500ms
  }

  .has-video-showing .porthole-inner {
    // background: rgba(#40414c, 0.9);
    // background: radial-gradient(circle, rgba(#40414c, 0.8) 0%, #25262b 100%);
    // background-color: rgba(#25262b, 0.85);

    background-color: rgba(#2d2e36, 0.85);
  }

  .porthole-inner-gradient-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: radial-gradient(circle, rgba(#40414c, 0.8) 0%, rgba(#25262b, 0) 100%);
    background: radial-gradient(circle, rgba(lighten(#2d2e36, 10%), 0.8) 10%, rgba(#2d2e36, 0) 70%);
  }

  // make logo text mask for programmatic manipulation
  // panels for twitch
  // find red accent, experiment

  //-----------------------------------------------------------------
  // LOGO
  //-----------------------------------------------------------------

  .porthole-logo {
    position: relative; // [LOCKED]
    top: rem-calc(118);
    left: rem-calc(-6); // eyeballing center (not mathematical)
    width: rem-calc(360); // 343
    margin: auto;
    // border: 1px dashed white;

    // transform: scale(1.03);
    z-index: 1;
  }

  .porthole-logo-base-glow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    // filter: drop-shadow(0px 0px 10px rgba($primary, 0.3));
    filter: drop-shadow(0 4px 1px rgba(black, 0.3));

    opacity: 0;
  }

  .porthole-logo-base-glow--hour {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    // filter: drop-shadow(0px 0px 10px rgba($primary, 0.3));
    filter: drop-shadow(0px 0px 15px rgba(#cccccc, 0.2));

    opacity: 0;
  }

  .porthole-logo.variation-2 {
    top: rem-calc(140);
  }

  .porthole-logo-goo,
  .porthole-logo-moon,
  .porthole-logo-bats,
  .porthole-logo-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 100%;
  }

  //-----------------------------------------------------------------
  // LOGO MOON
  //-----------------------------------------------------------------

  .porthole-logo-moon {
    left: auto;
    top: rem-calc(3);
    right: rem-calc(18);
    width: rem-calc(176);
    height: rem-calc(176);
    // border: 1px solid aqua;
    opacity: 0;
    overflow: hidden;
    animation: enter-moon 630ms 0ms ease alternate 1 forwards;
    will-change: transform;
    z-index: -1;

    @keyframes enter-moon {
      0% {
        opacity: 0;
        transform: scale(10);
      }
      94% {
        opacity: 1;
        transform: scale(1);
        height: rem-calc(176);
      }
      100% {
        opacity: 1;
        transform: scale(1);
        height: rem-calc(150);
      }
    }
  }

  .porthole-logo-moon .bg-fill {
    width: rem-calc(176);
    height: rem-calc(176);
    border-radius: 100%;
    background-color: #cccccc;
  }

  // VARIATION
  .variation-2 .porthole-logo-moon {
    // top: rem-calc(0);
    right: rem-calc(21);
    // width: rem-calc(192);
    // height: rem-calc(192);
  }

  //-----------------------------------------------------------------
  // LOGO GOO + BATS
  //-----------------------------------------------------------------

  .porthole-logo-goo {
    background-image: url("./assets/img/logo-goo.svg");
    transform: translateY(-20px);
    animation: goo 2s 400ms ease-out alternate 1 forwards;
    z-index: -1;

    @keyframes goo {
      100% {
        transform: translateY(0);
      }
    }
  }

  .porthole-logo-bats {
    top: rem-calc(3);
    background-image: url("./assets/img/logo-bats.svg");
  }

  //-----------------------------------------------------------------
  // LOGO TEXT
  //-----------------------------------------------------------------

  .porthole-logo-text {
    top: rem-calc(-40);
    left: rem-calc(-6);
    // border: 1px dashed yellow;
    font-family: $headings-font-family;
    font-size: rem-calc(25);
    font-weight: 600;
    line-height: 0.95;
    text-transform: uppercase;
    color: #dedede; // off-white

    // text-shadow: 0px 0px 3px rgba(white, 0.2);

    .text-1 {
      text-indent: rem-calc(100);
    }
    .text-2 {
      text-indent: rem-calc(55);
      opacity: 0.9;
    }
    .text-3 {
      text-indent: rem-calc(9); // 9
      opacity: 0.7;
    }
  }

  // VARIATION
  .variation-2 .porthole-logo-text {
    top: rem-calc(-50);
    left: rem-calc(0);
  }

  //-----------------------------------------------------------------
  // MESSAGE
  //-----------------------------------------------------------------

  .porthole-message {
    position: absolute;
    left: 0;
    top: 62%;
    width: 100%;
    text-align: center;
    font-family: $headings-font-family;
    font-weight: bold;
    line-height: 1;
    font-size: rem-calc(34); // 31
    color: #dedede; // off-white
    z-index: 1;
  }

  //-----------------------------------------------------------------
  // HEAD
  //-----------------------------------------------------------------

  .porthole-head {
    position: absolute;
    left: calc(50% - #{rem-calc(462 / 2)} / 2);
    bottom: rem-calc(-132);
    width: rem-calc(462 / 2);
    height: rem-calc(600 / 2);
    background: url("./assets/img/porthole-head.svg") no-repeat;
    background-size: 100%;
    filter: grayscale(0.6) brightness(70%);
    animation: enter-head 2000ms 0ms ease alternate 1 forwards;
    transition: transform 2s 2s ease;

    &.is-leaving {
      animation: leave-head 5s 100ms ease alternate 1 forwards;
    }

    @keyframes enter-head {
      0% {
        transform: translateY(100%);
      }
      100% {
        transform: translateY(0);
      }
    }

    @keyframes leave-head {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(100%);
      }
    }
  }
</style>
